<!-- 订单支付批量确认 -->
<template>
  <div>
    <el-dialog
        width="80%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        custom-class="ele-dialog-form"
        :title="isUpdate?'订单支付批量确认':'订单支付批量确认'"
        @update:visible="updateVisible">

      <el-form
          ref="form"
          :model="form"
          label-width="130px">

        <div class="marginstyle">
          待支付笔数：10，待支付金额：2000
        </div>

        <!-- 数据表格 -->
        <my-table
            ref="myTable"
            :columns="columns"
            :tableData="List"
            :cellStyle="cellStyle"
            :headerCellStyle="headerCellStyle"
            :rowClassName="rowClassName"
            :loading="loading1"
            @select="select"
        >
          <!--自定义列显示 示例-->
          <template v-slot:order_identity= "{ scope }">
            <div>
              {{ scope.row.order_identity }}
            </div>
          </template>

          <!-- 不使用数据可不接收scope值 -->
          <template v-slot:operationSlot= "{ scope }">
            <el-button @click="del(scope.row,scope.$index)">移除</el-button>
          </template>
        </my-table>
      </el-form>

      <div slot="footer">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            v-auths="[`${$config.uniquePrefix}orderModule:info:followup`]"
            @click="save">确认
        </el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies, setCookies} from '@/utils/cookies'
import Config from '@/config'

export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    selection: Array
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 上传图片
      dialogImageUrl: '',
      dialogVisible: false,
      upload_btn: false, // 是否隐藏上传图片按钮
      fileList:[],
      // 上传接口
      action: setting.apiBaseURL + 'common/upload/capacity',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },

      // 表格数据
      List: [],
      //加载动画
      loading1:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "订单号",
          // type: "index",
          // width: "70"
          prop: "order_id",
          isShow: true,
          fixed:'left',
        },
        {
          label: "案件号",
          // type: "index",
          // width: "70"
          prop: "case_number",
          isShow: true
        },
        {
          label: "下单时间",
          prop: "order_time",
          isShow: true
        },
        {
          label: "接单人",
          prop: "driver_name",
          isShow: true
        },
        {
          label: "案件地址",
          prop: "starting_point",
          isShow: true
        },
        {
          label: "受理人",
          prop: "acceptor_nickname",
          isShow: true
        },
        {
          label: "客户",
          prop: "order_identity",
          slot:'order_identity',
          isShow: true
        },
        {
          label: "订单属性",
          prop: "order_attribute",
          isShow: true
        },
        // {
        //   label: "客户",
        //   prop: "facilitator_name",
        //   isShow: true
        // },
        {
          label: "订单来源",
          prop: "channel_name",
          isShow: true
        },
        {
          label: "救援类型",
          prop: "items_name",
          isShow: true
        },
        {
          label: "状态",
          prop: "status_label",
          isShow: true
        },
        {
          label: "支付状态",
          prop: "",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          fixed:'right',
          slot: "operationSlot",
          isShow: true
        },
      ],

    };
  },


  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.form.remark = '';
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    this.List = this.selection;
    this.loading1 = false;
  },

  methods: {
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
      this.selection = selection;
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 移除
    del(row,index){
      console.log(row)
      console.log(index)
      this.List.splice(index,1);
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.$emit('show');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">
  .marginstyle{
    margin-bottom: 15px;
    font-size: 18px;
  }
</style>
